<template>
	<div class="eye-exam-container">
		<div class="container-header">
			<h3>{{ title }}</h3>
			<div class="chevron" 
				@click="handleOpen()" 
				v-bind:class="{ rotateUp: !open, rotateDown: open }"
			>
				<ChevronUp />
			</div>
		</div>

		<div class="exams" v-if="this.open" v-bind:class="{ openTable: open, closeTable: !open }">
			<RecordEyeExam 
                v-if="form?.olhoEsquerdo || form?.olhoDireito"
				:equipment="form?.aparelho" 
				:hour="form?.horario" 
				:leftEye="form?.olhoEsquerdo"
				:rightEye="form?.olhoDireito" 
			/>
			<RecordEyeExam 
				v-if="form?.type === 'tonometria'" 
				:equipment="form.value?.aparelho"
				:hour="form.value?.horario" 
				:leftEye="form.value?.olhoEsquerdo"
				:rightEye="form.value?.olhoDireito" 
			/>
			<RecordEyeExam 
				v-if="form?.tonometria" 
				:equipment="form.tonometria.aparelho"
				:hour="form.tonometria.horario" 
				:leftEye="form.tonometria.olhoEsquerdo"
				:rightEye="form.tonometria.olhoDireito" 
			/>
		</div>
	</div>
</template>

<script>
import ChevronUp from '@/assets/icons/chevron-up.svg'

import RecordEyeExam from './RecordEyeExam'

export default {

	components: { ChevronUp, RecordEyeExam },

	props: {
		title: String,
		form: Object,
	},

	data() {
		return {
			open: true,
		}
	},

	methods: {
		handleOpen() {
			this.open = !this.open
		}
	},
}
</script>

<style lang="scss" scoped>
.eye-exam-container {
	display: flex;
	flex-direction: column;
	margin: 20px 0;

	.container-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-right: 30px;

		h3 {
			font-size: 18px;
			color: var(--dark-blue);
			font-weight: 600;
		}

		.chevron {
			width: 25px;
			height: 25px;

			>svg {
				cursor: pointer;
				stroke: var(--neutral-600);
				transition: .5s;
			}
		}

		.rotateUp {
			animation: rotateUp .5s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}

		.rotateDown {
			animation: rotateDown .5s;
			-webkit-animation-fill-mode: forwards;
			-moz-animation-fill-mode: forwards;
			animation-fill-mode: forwards;
		}

		@keyframes rotateUp {
			0% {
				transform: rotate(0deg);
			}

			100% {
				transform: rotate(180deg);
			}
		}

		@keyframes rotateDown {
			0% {
				transform: rotate(180deg);
			}

			100% {
				transform: rotate(0deg);
			}
		}

	}

	h3 {
		color: var(--dark-blue);
		font-weight: 600;
		font-size: 18px;
	}
}

.exams {
	display: flex;
	flex-direction: column;
}

fieldset {
	*:read-only {
		background-color: var(--neutral-100) !important;
	}
}
</style>
