<template>
  <section class="exam-data">
    <div class="values">
      <div class="equipment">
        <p class="label">Aparelho</p>
        <fieldset>
          <b-form-input autocomplete="off" v-model="equipment" :readonly="true" aria-label="Aparelho"></b-form-input>
        </fieldset>
      </div>
      <div class="hour">
        <p class="label">Horário</p>
        <fieldset>
          <b-form-input autocomplete="off" v-model="hour" :readonly="true" aria-label="Horário"></b-form-input>
        </fieldset>
      </div>
    </div>
    <div class="eyes">
      <div>
        <p class="label">Olho direito</p>
        <b-input-group>
          <b-input-group-prepend is-text>
            <Eye class="form-icon" />
            D
          </b-input-group-prepend>
          <b-form-input autocomplete="off" v-model="rightEye" :readonly="true" aria-label="Olho direito"></b-form-input>
        </b-input-group>
      </div>
      <div>
        <p class="label">Olho esquerdo</p>
        <b-input-group>
          <b-input-group-prepend is-text>
            <Eye class="form-icon" />
            E
          </b-input-group-prepend>
          <b-form-input autocomplete="off" v-model="leftEye" :readonly="true" aria-label="Olho esquerdo"></b-form-input>
        </b-input-group>
      </div>
    </div>
  </section>
</template>

<script>
import Eye from '@/assets/icons/eye.svg'

export default {

  components: { Eye },

  props: {
    title: String,
    equipment: String,
    hour: String,
    leftEye: String,
    rightEye: String,
  },
}
</script>


<style lang="scss" scoped>
.exam-data {
  display: flex;
  flex-direction: row;

  .values {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .equipment {
      width: 80%;
      display: flex;
      flex-direction: column;

      .input-group {
        .form-control {
          border-radius: 8px !important;
        }
      }
    }

    .hour {
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: end;

      .input-group {
        .form-control {
          border-radius: 8px !important;
        }
      }
    }
  }

  .eyes {
    width: 50%;
    display: flex;
    flex-direction: row;
  }

  .values,
  .eyes {

    >div {
      margin: 10px 20px 15px 0px;

      .label {
        color: var(--dark-blue);
        font-weight: 600;
        margin-bottom: 5px;
      }
    }

  }

  .form-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .input-group {
    .input-group-text {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      font-weight: 600;
      width: 62px;
      color: #525c7a;
      background-color: var(--neutral-100);
    }

    input[readonly] {
      background-color: var(--neutral-100);
    }

    .form-control {
      border-radius: 0 8px 8px 0 !important;
    }
  }

}
</style>